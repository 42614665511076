import * as React from 'react';
import Layout from '../../components/Layout';
import Birthstone from '../../components/Birthstone';
import Header from '../../components/Header';
import { Box, Container, Stack, Typography } from '@mui/material';
import Footer from '../../components/Footer';

export default function Birthstones(): JSX.Element {
  return (
    <Layout title="Birthstones Guide | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="guides" />
        <Box flex={1}>
          <Container>
            <Typography variant="h1" gutterBottom>Birthstones</Typography>
            <Typography variant="body1" gutterBottom>
              A birthstone is a gemstone most often in the form of a ring or
              pendant. Each stone is traditionally associated with various
              qualities that symbolize the month of birth in the Gregorian calendar.
            </Typography>
            <Typography variant="body1" mb={6}>
              Tiffany &amp; Co. published these poems of unknown author for the
              first time in a pamphlet in 1870.
            </Typography>
            <Stack direction="row" justifyContent="center">
              <Stack direction="column" gap={4} mb={4}>
                <Birthstone
                  imageSrc="/images/birthstones/january.png"
                  title="January: Garnet"
                  text={`By her who in this month is born
                    No gem save garnets should be worn;
                    They will ensure her constancy,
                    True friendship, and fidelity.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/february.png"
                  title="February: Amethyst"
                  text={`The February-born shall find
                    Sincerity and peace of mind,
                    Freedom from passion and from care,
                    If they an amethyst will wear.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/march.png"
                  title="March: Aquamarine / Bloodstone"
                  text={`Who in this world of ours their eyes
                    In March first open shall be wise,
                    In days of peril firm and brave,
                    And wear a bloodstone to their grave.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/april.png"
                  title="April: Diamond / Rock Crystal"
                  text={`She who from April dates her years,
                    Diamonds shall wear, lest bitter tears
                    For vain repentance flow; this stone,
                    Emblem of innocence, is known.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/may.png"
                  title="May: Emerald / Chrysoprase"
                  text={`Who first beholds the light of day
                    In spring's sweet flowery month of May
                    And wears an emerald all her life
                    Shall be a loved and happy wife.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/june.png"
                  title="June: Pearl / Moonstone"
                  text={`Who comes with summer to this earth,
                    And owes to June her hour of birth,
                    With ring of agate on her hand
                    Can health, wealth, and long life command.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/july.png"
                  title="July: Ruby / Carnelian / Onyx"
                  text={`The glowing ruby shall adorn,
                    Those who in July are born;
                    Then they'll be exempt and free
                    From love's doubts and anxiety.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/august.png"
                  title="August: Peridot / Sardonyx"
                  text={`Wear a sardonyx or for thee,
                    No conjugal felicity;
                    The August-born without this stone,
                    'Tis said, must live unloved and lone.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/september.png"
                  title="September: Sapphire / Lapis Lazuli"
                  text={`A maiden born when September leaves
                    Are rustling in September's breeze,
                    A sapphire on her brow should bind
                    'Twill cure diseases of the mind.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/october.png"
                  title="October: Opal / Pink Tourmaline"
                  text={`October's child is born for woe,
                    And life's vicissitudes must know,
                    But lay an opal on her breast,
                    And hope will lull those woes to rest.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/november.png"
                  title="November: Topaz / Citrine"
                  text={`Who first comes to this world below
                    With drear November's fog and snow,
                    Should prize the topaz's amber hue,
                    Emblem of friends and lovers true.`}
                />
                <Birthstone
                  imageSrc="/images/birthstones/december.png"
                  title="December: Turquoise"
                  text={`If cold December gave you birth,
                    The month of snow and ice and mirth,
                    Place on your hand a turquoise blue;
                    Success will bless whate'er you do.`}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}
