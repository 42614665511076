import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

type BirthstoneProps = {
  imageSrc: string;
  title: string;
  text: string;
}

export default function Birthstone({ imageSrc, title, text }: BirthstoneProps): JSX.Element {
  const lines = text.split(/\r?\n/);
  const textWithBreaks = lines.map((l) => (
    <>
      {l}
      <br />
    </>
  ));

  return (
    <Stack direction="row">
      <Box sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          height: '90px',
        },
      })}>
        <img src={imageSrc} alt="" css={{height: 'inherit'}} />
      </Box>
      <Box ml={[1, 4]} alignSelf="center" sx={{overflowWrap: 'anywhere'}}>
        <Typography variant="h3" component="h2" gutterBottom>{ title }</Typography>
        <Typography variant="body1">
          {textWithBreaks}
        </Typography>
      </Box>
    </Stack>
  );
}
